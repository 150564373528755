import { useEffect, useMemo } from 'react';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Decimal from 'decimal.js';
import useFeathersNoPagination from 'hooks/useFeathersNoPagination';
import { lookups as lookupGames } from 'lookups/games';

export default function usePlayerGameIds(companyId, username) {
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const {
    result: games,
    isFetching: isFetchingGames,
    error: gamesError,
  } = useFeathersNoPagination('games', {
    query: {
      companyId,
      isEnabled: true,
    },
  }, {
    liveUpdatePredicate: (data) => data.companyId === companyId,
  });

  const {
    result: gameIds,
    isFetching: isFetchingGameIds,
    error: gameIdsError,
  } = useFeathersNoPagination('game-ids', {
    query: {
      companyId,
      username,
    },
  }, {
    liveUpdatePredicate: (data) => {
      return data.companyId === companyId && data.username === username;
    }
  });

  useEffect(() => {
    if (!gamesError) return;

    setGlobalErrorMessage({ err: gamesError });
  }, [gamesError, setGlobalErrorMessage]);

  useEffect(() => {
    if (!gameIdsError) return;

    setGlobalErrorMessage({ err: gameIdsError });
  } , [gameIdsError, setGlobalErrorMessage]);

  function filterGames(data = []) {
    return data.filter((game) => {
      const { isEnabled = false, type = '' } = game;
      const isApiType = type.endsWith('-sapi') || type.endsWith('-api');
      return isEnabled && !isApiType;
    });
  }

  const filteredGames = useMemo(() => {
    return filterGames(games);
  }, [games]);

  const isFetching = useMemo(() => {
    return isFetchingGames || isFetchingGameIds;
  }, [isFetchingGames, isFetchingGameIds]);

  const gameIdsMap = useMemo(() => {
    if (isFetching) return [];
    const gameIdsMap = filteredGames.map((game) => {
      const gameType = game.type;
      const gameName = lookupGames[gameType] || gameType;

      const gameData = gameIds.find((gameIdData) => gameIdData.gameType === gameType);
      const { gameId, gamePassword, balance } = gameData || {};
      const formattedBalance = new Decimal(balance?.$numberDecimal || 0).toNumber();

      return {
        _id: game._id,
        gameIdUid: gameData?._id,
        gameType,
        gameName,
        gameId,
        gamePassword,
        balance: formattedBalance,
      };
    });

    const sortedGameIdsMap = gameIdsMap.sort((a, b) => {
      if (a.balance > b.balance) return -1; // sort by balance descending
      if (a.balance < b.balance) return 1; // sort by balance descending
      if (a.gameName < b.gameName) return -1; // sort by gameName ascending
      if (a.gameName > b.gameName) return 1; // sort by gameName ascending
      return 0;
    });

    return sortedGameIdsMap;
  }, [filteredGames, gameIds, isFetching]);

  return {
    data: gameIdsMap,
    isFetching,
  }
}