import { useState, useEffect } from 'react';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import Decimal from 'decimal.js';

export default function usePlayerWallet(companyId, username) {
  const service = feathers.service('wallets');
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const { setGlobalErrorMessage } = useGlobalMessageActionsContext();

  function extractBalances(data) {
    const { cashBalance, pointBalance } = data;
    return {
      cashBalance: new Decimal(cashBalance?.$numberDecimal || 0).toNumber(),
      pointBalance: new Decimal(pointBalance?.$numberDecimal || 0).toNumber(),
    };
  }

  useEffect(() => {
    if (!username) return;
    setIsFetching(true);
    service.find({
      query: {
        companyId,
        username,
      },
    }).then((response) => {
      const balances = extractBalances(response.data[0]);
      setData(balances);
    }).catch((err) => {
      setGlobalErrorMessage({ err });
    }).finally(() => {
      setIsFetching(false);
    });
  }, [username, companyId, service, setGlobalErrorMessage]);

  useEffect(() => {
    if (!companyId || !username) return;
    const listener = (data) => {
      if (data.companyId === companyId && data.username === username) {
        const balances = extractBalances(data);
        setData(balances);
      }
    };

    service.on('patched', listener);

    return () => {
      service.removeListener('patched', listener);
    };
  }, [companyId, username, service]);

  return {
    data,
    isFetching,
  }
}