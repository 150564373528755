import React, { useContext } from 'react';
import { useField, useFormikContext } from 'formik';
import {
  TextField,
} from '@mui/material';
import { AbilityContext } from 'casl/Can';

export default function FormikText(props) {
  const [ field, meta ] = useField(props);
  const { initialValues } = useFormikContext();
  const ability = useContext(AbilityContext);

  const { caslSubject = '', _id } = initialValues;
  const { name: fieldName } = field;

  const action = _id === undefined ? 'create' : 'update';
  const isAllowed = ability.can(action, caslSubject, fieldName);
  const { disabled: disabledProp = false } = props;

  return (
    <TextField
      { ...field }
      { ...props }
      disabled={isAllowed === false || disabledProp}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
}
