import React, { Suspense } from 'react';
import GlobalMessageContextProvider from 'features/context/GlobalMessageContext';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";
import Loader from 'features/loader/Loader';
import RequireAuth from 'features/login/RequireAuth';
import CommonProvider from 'features/context/CommonProvider';
import BankProvider from 'features/context/BankProvider';
import BankBotProvider from 'features/context/BankBotProvider';
import AssetProvider from 'features/context/AssetProvider';
import PromptProvider from 'features/context/PromptProvider';
import { UserDialogProvider } from 'features/context/userDialogContext';
import { GameManagementDialogProvider } from 'features/context/gameManagementDialogContext';

// Pages
import Login from 'features/login/Login';
import Logout from 'features/login/Logout';
import Layout from 'features/layout/Layout';
import Home from 'features/home/Home';
import Account from 'features/account/Account';
import News from 'features/news/News';
import Users from 'features/users/Users';
import UserRelations from 'features/userRelations/UserRelations';
import Deposits from 'features/deposits/Deposits';
import Withdrawals from 'features/withdrawals/Withdrawals';
import BalanceTransfers from 'features/balanceTransfers/BalanceTransfers';
import BankAdjustments from 'features/bankAdjustments/BankAdjustments';
import WalletAdjustments from 'features/walletAdjustments/WalletAdjustments';
import GameUrls from 'features/gameUrls/GameUrls';
import Games from 'features/games/Games';
import RedemptionDeals from 'features/redemptionDeals/RedemptionDeals';
import MemberTiers from 'features/memberTiers/MemberTiers';
import CashRebateSettings from 'features/cashRebateSettings/CashRebateSettings';
import CashRebates from 'features/cashRebates/CashRebates';
import PointBoosters from 'features/pointBoosters/PointBoosters';
import WhatsAppBots from 'features/whatsAppBots/WhatsAppBots';
import TelegramBots from 'features/telegramBots/TelegramBots';
import LineBots from 'features/lineBots/LineBots';
import CustomerServices from 'features/customerServices/CustomerServices';
import Kiosks from 'features/kiosks/Kiosks';
import CompanyBanks from 'features/companyBanks/CompanyBanks';
import ClientBanks from 'features/clientBanks/ClientBanks';
import LuckyDrawSettings from 'features/luckyDrawSettings/LuckyDrawSettings';
import LuckyDraws from 'features/luckyDraws/LuckyDraws';
import WalletHistory from 'features/walletHistory/WalletHistory';
import LogonLogs from 'features/logonLogs/LogonLogs';
import GameIds from 'features/gameIds/GameIds';
import BankBots from 'features/bankBots/BankBots';
import BankTransactions from 'features/bankTransactions/BankTransactions';
import Announcements from 'features/announcements/Announcements';
import GameLogs from 'features/gameLogs/GameLogs';
import DeviceFingerprints from 'features/deviceFingerprints/DeviceFingerprints';
import CompanySettings from 'features/companySettings/CompanySettings';
import GameApiSettings from 'features/gameApiSettings/GameApiSettings';
import Assets from 'features/assets';
import LuckyWheels from 'features/luckyWheels/LuckyWheels';
import ThemesPage from 'features/themes/Themes';
import Chats from 'features/chats/Chats';
import CorsOrigins from 'features/corsOrigins/CorsOrigins';
import { useUserConfig } from 'hooks/useUserConfig';
import { SnackbarProvider } from 'notistack';
import { ProvideChats } from 'hooks/useChats';

// Themes
import { ThemeProvider } from '@mui/material/styles';
import lightTheme from './themes/light';
import darkTheme from './themes/dark';

// Fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

function App() {
  const { darkMode } = useUserConfig();

  const router = createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={
          <RequireAuth>
            <CommonProvider>
              <UserDialogProvider>
                <PromptProvider>
                  <ProvideChats>
                    <GameManagementDialogProvider>
                      <Layout />
                    </GameManagementDialogProvider>
                  </ProvideChats>
                </PromptProvider>
              </UserDialogProvider>
            </CommonProvider>
          </RequireAuth>
        }>
          <Route index element={<Home />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="account" element={<Account />} />
          <Route path="news" element={
            <AssetProvider>
              <News />
            </AssetProvider>
          } />
          <Route path="announcements" element={<Announcements />} />
          <Route path="users" element={<Users />} />
          <Route path="user-relations" element={<UserRelations />} />
          <Route path="deposits" element={
            <BankProvider>
              <Deposits />
            </BankProvider>
          } />
          <Route path="withdrawals" element={
            <BankProvider>
              <BankBotProvider>
                <Withdrawals />
              </BankBotProvider>
            </BankProvider>
          } />
          <Route path="balance-transfers" element={<BalanceTransfers />} />
          <Route path="bank-adjustments" element={
            <BankProvider>
              <BankAdjustments />
            </BankProvider>
          } />
          <Route path="wallet-adjustments" element={<WalletAdjustments />} />
          <Route path="game-urls" element={<GameUrls />} />
          <Route path="games" element={<Games />} />
          <Route path="redemption-deals" element={<RedemptionDeals />} />
          <Route path="member-tiers" element={
            <AssetProvider>
              <MemberTiers />
            </AssetProvider>
          } />
          <Route path="point-boosters" element={
            <AssetProvider>
              <PointBoosters />
            </AssetProvider>
          } />
          <Route path="whatsapp-bots" element={<WhatsAppBots />} />
          <Route path="telegram-bots" element={<TelegramBots />} />
          <Route path="line-bots" element={<LineBots />} />
          <Route path="customer-services" element={
            <AssetProvider>
              <CustomerServices />
            </AssetProvider>
          } />
          <Route path="kiosks" element={<Kiosks />} />
          <Route path="game-api-settings" element={<GameApiSettings />} />
          <Route path="company-banks" element={<CompanyBanks />} />
          <Route path="client-banks" element={<ClientBanks />} />
          <Route path="lucky-draw-settings" element={
            <AssetProvider>
              <LuckyDrawSettings />
            </AssetProvider>
          } />
          <Route path="lucky-draws" element={
            <AssetProvider>
              <LuckyDraws />
            </AssetProvider>
          } />
          <Route path="wallet-history" element={<WalletHistory />} />
          <Route path="game-logs" element={<GameLogs />} />
          <Route path="logon-logs" element={<LogonLogs />} />
          <Route path="game-ids" element={<GameIds />} />
          <Route path="bank-bots" element={<BankBots />} />
          <Route path="device-fingerprints" element={<DeviceFingerprints />} />
          <Route path="bank-transactions" element={<BankTransactions />} />
          <Route path="company-settings" element={
            <AssetProvider>
              <CompanySettings />
            </AssetProvider>
          } />
          <Route path="cash-rebate-settings" element={<CashRebateSettings />} />
          <Route path="cash-rebates" element={<CashRebates />} />
          <Route path="assets" element={
            <AssetProvider>
              <Assets />
            </AssetProvider>
          } />
          <Route path="lucky-wheels" element={
            <AssetProvider>
              <LuckyWheels />
            </AssetProvider>
          } />
          <Route path="themes" element={
            <AssetProvider>
              <ThemesPage />
            </AssetProvider>
          } />
          <Route path="chats" element={<Chats />} />
          <Route path="cors-origins" element={<CorsOrigins />} />
          <Route path="*" element={<Navigate to='/' replace />} />
        </Route>
      </>
    )
  );


  return (
    <ThemeProvider theme={ darkMode? darkTheme : lightTheme }>
      <Suspense fallback={<Loader open={true} />}>
        <SnackbarProvider maxSnack={5}>
          <GlobalMessageContextProvider>
            <RouterProvider router={router} />
          </GlobalMessageContextProvider>
        </SnackbarProvider>
      </Suspense>
    </ThemeProvider>
  );
}

export default App;
