import React, { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
//import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { get } from 'lodash';
import { useTranslation } from 'react-i18next';
import feathers from 'services/feathers';
import { useGlobalMessageActionsContext } from 'features/context/GlobalMessageContext';
import LoadingButton from '@mui/lab/LoadingButton';
import ProofOfTransfer from 'features/img/ProofOfTransfer';
import Typography from '@mui/material/Typography';
import Decimal from 'decimal.js';
import CancelIcon from '@mui/icons-material/Cancel';
import DoneIcon from '@mui/icons-material/CheckCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Chip from '@mui/material/Chip';
import { getFullBankLabel } from 'utils/bank';
import { UserDialogContext } from 'features/context/userDialogContext';
import Uploader from 'features/uploader/PotUploader';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const _SERVICENAME = 'deposits';

export default function Form(props) {
  const { t } = useTranslation();
  const { open, setOpen, data: propData } = props;
  const { onOpen: onUserDialogOpen } = useContext(UserDialogContext);
  const service = feathers.service(_SERVICENAME);
  const [potRefId, setPotRefId] = useState(null);
  const [pastedImage, setPastedImage] = useState(null);

  const initialValues = useMemo(
    () => {
      const _id = get(propData, '_id');
      const transactedAt = get(propData, 'transactedAt', new Date());
      const state = get(propData, 'state');
      const remark = get(propData, 'remark', '');
      return {
        _id,
        transactedAt,
        state,
        remark,
      }
    }, [propData]
  );

  const [transactionState, setTransactionState] = useState(get(propData, 'state', ''));

  const transactionId = useMemo(
    () => {
      return get(propData, '_id');
    }, [propData]
  );

  const readOnly = useMemo(
    () => {
      return transactionState === 'check' ? false : true;
    }, [transactionState]
  );

  const data = useMemo(
    () => {
      const _id = get(propData, '_id');
      const username = get(propData, 'username', '');
      const companyId = get(propData, 'companyId');
      const amountStr = get(propData, 'amount.$numberDecimal', '0');
      const amount = new Decimal(amountStr).toFixed(2);
      const name = get(propData, 'companyBank.name', '');
      const bankNameRaw = get(propData, 'companyBank.bankName', '');
      const accountName = get(propData, 'companyBank.accountName', '');
      const rawDisplayNumber = get(propData, 'companyBank.displayNumber', '');
      const accountNumber = get(propData, 'clientBank.accountNumber', '');
      const skipReward = get(propData, 'skipReward', false);
      const designatedRef = get(propData, 'designatedRef', '');

      const bankOption = get(propData, 'bankOption');
      const bankName = getFullBankLabel(bankNameRaw, bankOption);
      const hasBsb = get(propData, 'bankOption.hasBsb', false);
      const bsbNumber = hasBsb ? rawDisplayNumber.substring(0, 6) : '';
      const displayNumber = hasBsb ? rawDisplayNumber.substring(6) : rawDisplayNumber;

      return {
        _id,
        username,
        companyId,
        amount,
        name,
        bankName,
        accountName,
        displayNumber,
        accountNumber,
        designatedRef,
        ...(
          !!bsbNumber && {
            bsbNumber
          }
        ),
        skipReward
      };
    }, [propData]
  );

  useEffect(() => {
    setPotRefId(get(propData, 'potRefId'));
  }, [propData]);

  const showDropzone = useMemo(
    () => {
      return !potRefId
    }, [potRefId]
  );

  const [status, setStatus] = useState('idle');
  const { setGlobalMessage, setGlobalErrorMessage } = useGlobalMessageActionsContext();

  const onUsernameClicked = useCallback(
    (username, companyId) => (event) => {
      event?.preventDefault();
      onUserDialogOpen(username, companyId);
    }, [onUserDialogOpen]
  );

  const onPatched = useCallback(
    (data) => {
      if (!transactionId) return;
      if (get(data, '_id') !== transactionId) return;
      const state = get(data, 'state');
      setTransactionState(state);
    },
    [transactionId]
  );

  useEffect(() => {
    if (transactionState === 'done') return;

    service.on('updated', onPatched);
    service.on('patched', onPatched);

    return () => {
      service.removeListener('updated', onPatched);
      service.removeListener('patched', onPatched);
    };
  }, [service, onPatched, transactionState]);

  const dataSchema = Yup.object().shape({
    transactedAt: Yup.date().required(t("Required")),
    //state: Yup.string().matches(/(initial|canceling)/),
    state: Yup.string().nullable(),
    remark: Yup.string().nullable(),
  });

  const formik = useFormik({
    enableReinitialize: false,
    initialValues: initialValues,
    validationSchema: dataSchema,
    onSubmit: async values => {
      try {
        setStatus('submitting');
        const _id = get(values, '_id');
        const transactedAt = get(values, 'transactedAt');
        const remark = get(values, 'remark');
        const state = get(values, 'state');
        const formData = {
          transactedAt,
          remark,
          ...(
            state && {
              state: state
            }
          )
        }
        await service.patch(_id, formData);
        setStatus('idle');
        setGlobalMessage({
          message: t(`Saved`),
          severity: 'success'
        });
      } catch (err) {
        setGlobalErrorMessage({ err });
        setStatus('idle');
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (event) => {
    event.preventDefault();
    formik.handleSubmit();
  };

  const handleUploadedPot = (refId) => {
    setPotRefId(refId);
    setPastedImage(null);
  };

  const handlePotDeleteClick = useCallback(
    async (event) => {
      event.preventDefault();

      if (!potRefId) return;

      try {
        const _id = get(data, '_id');
        await service.patch(_id, {
          $unset: {
            potRefId: 1
          }
        });
        setPotRefId(null);
      } catch (err) {
        setGlobalErrorMessage({ err });
      }
    }, [data, potRefId, service, setGlobalErrorMessage]
  );

  const handleImagePaste = (event) => {
    if (!showDropzone) return;
    const item = get(event, 'clipboardData.items[0]');
    const itemType = get(item, 'type', '');

    if (itemType.indexOf('image') !== 0) return;
    const blob = item.getAsFile();
    setPastedImage(blob);
  };

  const handleWhatsAppClicked = (event) => {
    event.preventDefault();
    window.open(`https://wa.me/${data?.username}/?text=`, '_blank');
  };

  function getChipColor() {
    return transactionState === 'done' ? 'success' : transactionState === 'canceled' ? 'error' : 'default';
  }

  return (
    <>
      <Dialog maxWidth='xl' open={open} onClose={handleClose}>
        <DialogTitle>
          {t('Deposit')}
          {
            transactionState && <Chip size='small' sx={{ ml: 1, borderRadius: 1, fontWeight: 700 }} label={t(transactionState)} color={getChipColor()} />
          }
          {
            data.skipReward && <Chip size='small' sx={{ ml: 1, borderRadius: 1, fontWeight: 700 }} label={t('Skip Reward')} color='error' />
          }
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {
              showDropzone &&
              <Grid item xs={12} md={4}>
                <Uploader refId={data._id} onUploaded={handleUploadedPot} pastedImage={pastedImage} uploadPath='pot-upload-dp' />
              </Grid>
            }
            {
              !!potRefId &&
              <Grid item xs={12} md={4}>
                <Paper elevation={8} sx={{ p: 1, position: 'relative' }}>
                  <ProofOfTransfer sx={{ width: '100%', border: 1, borderRadius: 1, borderColor: 'divider' }} potId={potRefId} />
                  <IconButton onClick={handlePotDeleteClick} color='error' size='large' sx={{ position: 'absolute', top: 1, left: 1 }}>
                    <DeleteForeverIcon fontSize='large' />
                  </IconButton>
                </Paper>
              </Grid>
            }
            <Grid item xs={12} md={4}>
              <Paper elevation={8} sx={{ p: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', gap: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                    {t('Bank')}
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                    <Typography variant='subtitle1'>
                      {data.name}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {data.bankName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                    {t('Account Name')}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {data.accountName}
                  </Typography>
                </Box>
                {
                  !!data?.bsbNumber &&
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                      {t('BSB Number')}
                    </Typography>
                    <Typography variant='subtitle1'>
                      {data.bsbNumber}
                    </Typography>
                  </Box>
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                    {t('Account Number')}
                  </Typography>
                  <Typography variant='subtitle1'>
                    {data.displayNumber}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                    {t('Amount')}
                  </Typography>
                  <Typography variant='h4' sx={{ fontWeight: 700 }}>
                    {`$${data.amount}`}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                      {t('Username')}
                    </Typography>
                    <IconButton
                      sx={{ color: '#25D366', '&:hover': { color: '#1da851' } }}
                      onClick={handleWhatsAppClicked}
                      size='small'
                    >
                      <WhatsAppIcon />
                    </IconButton>
                  </Box>
                  <Typography variant='subtitle1'>
                    <Box
                      component='span'
                      sx={{
                        textDecoration: 'underline',
                        textDecorationColor: theme => (theme.palette.info.main),
                        textDecorationThickness: 2,
                        cursor: 'pointer',
                      }}
                      onClick={onUsernameClicked(data.username, data.companyId)}
                    >
                      {data.username}
                    </Box>
                  </Typography>
                </Box>
                {!!data?.designatedRef &&
                  <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Typography variant='subtitle1' sx={{ fontWeight: 300 }}>
                      {t('Designated Ref')}
                    </Typography>
                    <Typography variant='subtitle1' sx={{ fontStyle: 'italic', color: 'text.secondary' }}>
                      {data.designatedRef}
                    </Typography>
                  </Box>
                }
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper elevation={8} sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        views={['day', 'hours', 'minutes', 'seconds']}
                        inputFormat="YYYY-MM-DD HH:mm:ss"
                        renderInput={(props) => <TextField fullWidth {...props} />}
                        id='transactedAt'
                        label={t('Transacted At')}
                        value={get(formik, 'values.transactedAt', '')}
                        onBlur={formik.handleBlur}
                        onChange={(date) => {
                          formik.setFieldValue('transactedAt', date.toDate());
                        }}
                        error={get(formik, 'touched.transactedAt', false) && Boolean(get(formik, 'errors.transactedAt'))}
                        helperText={get(formik, 'touched.transactedAt', false) && get(formik, 'errors.transactedAt')}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={2}
                      id='remark'
                      name='remark'
                      label={t('Remark')}
                      value={formik.values.remark}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      error={get(formik, `touched.remark`, false) && Boolean(get(formik, `errors.remark`))}
                      helperText={get(formik, `touched.remark`, false) && get(formik, `errors.remark`)}
                      onPaste={handleImagePaste}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={readOnly}
                      onClick={() => formik.setFieldValue('state', 'initial')}
                      size='large'
                      fullWidth
                      variant={formik.values.state === 'initial' ? 'contained' : 'outlined'}
                      color={formik.values.state === 'initial' ? 'success' : 'primary'}
                      startIcon={<DoneIcon />}
                    >
                      {t('Done')}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      disabled={readOnly}
                      onClick={() => formik.setFieldValue('state', 'canceling')}
                      size='large'
                      fullWidth
                      variant={formik.values.state === 'canceling' ? 'contained' : 'outlined'}
                      color={formik.values.state === 'canceling' ? 'error' : 'primary'}
                      startIcon={<CancelIcon />}
                    >
                      {t('Cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('Close')}</Button>
          <LoadingButton loading={status !== 'idle'} loadingIndicator={t('Saving')} onClick={handleSave}>{t('Save')}</LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

Form.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
