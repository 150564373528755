import useSWR from 'swr';
import feathers from 'services/feathers';
import { useEffect } from 'react';

export default function useCompanyBanks(purposes = ['deposit', 'withdrawal']) {

  const { data, error, isLoading, mutate } = useSWR(`company-banks`, async () => {
    const response = await feathers.service('company-banks').find({
      query: {
        purposes: { $in: purposes },
        isHidden: false,
        $limit: -1,
        $populate: [{
          'path': 'data',
        }, {
          'path': 'bankOption',
        }],
      },
    });

    return response;
  });

  useEffect(() => {
    const companyBankDataService = feathers.service('company-bank-data');

    const onPatch = () => {
      mutate();
    };

    companyBankDataService.on('patched', onPatch);

    return () => {
      companyBankDataService.removeListener('patched', onPatch);
    };
  }, [mutate]);

  return {
    data,
    isLoading,
    error,
    mutate,
  };
}