import React from 'react';

export const GameManagementDialogContext = React.createContext({
  open: false,
  onClose: () => {},
  username: null,
  companyId: null,
  onOpen: () => {},
});

export const GameManagementDialogProvider = ({ children }) => {
  const [open, setOpen] = React.useState(false);
  const [username, setUsername] = React.useState(null);
  const [companyId, setCompanyId] = React.useState(null);

  const handleClose = React.useCallback(() => {
    setUsername(null);
    setCompanyId(null);
    setOpen(false);
  }, []);

  const handleOpen = React.useCallback((username, companyId) => {
    setUsername(username);
    setCompanyId(companyId);
    setOpen(true);
  }, []);

  const value = {
    open,
    onClose: handleClose,
    username,
    companyId,
    onOpen: handleOpen,
  };

  return (
    <GameManagementDialogContext.Provider value={value}>
      {children}
    </GameManagementDialogContext.Provider>
  );
};
